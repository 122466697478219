import React, { useState, useEffect, useRef } from 'react';
import { Paper, InputBase, IconButton, Snackbar, Menu, MenuItem } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Alert from '@mui/material/Alert';


function Console() {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState([]);
  const messageTimeoutRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false); // For connection success notification

  const [suggestCommands, setSuggestCommands] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);


  const ws = useRef(null);
  const bottomRef = useRef(null);  // 自動スクロール用の ref

  const shouldReconnect = useRef(true);

  
  useEffect(() => {
    connectWebSocket();
    // Setup the disconnection logic on unmount here instead.
    return () => {
      shouldReconnect.current = false; // Signal to prevent reconnection attempts
      ws.current.close();
      if (messageTimeoutRef.current) {
        clearTimeout(messageTimeoutRef.current);
      }
    };
  }, []);

  const connectWebSocket = () => {
    ws.current = new WebSocket('ws://int-gw.kuc.jp/ws');

    ws.current.onopen = () => {
      console.log("ws opened");
      setIsConnected(true);
      setOpenSuccessSnackbar(true);
      setOpenSnackbar(false);
    };

    ws.current.onclose = () => {
      console.log("ws closed");
      setIsConnected(false);
      setOpenSnackbar(true);
      // Ensure we don't stack reconnect attempts
      if (shouldReconnect.current) {
        setTimeout(() => {
          connectWebSocket();
        }, 1000);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.current.onmessage = (e) => {
      const message = e.data;
    
      // Clear existing timeout
      if (messageTimeoutRef.current) {
        clearTimeout(messageTimeoutRef.current);
      }
    

      try {
        // Try to parse the message as JSON
        const jsonMessage = JSON.parse(message);
    
        // Check if the message is a valid 'next_commands' type and 'suggest' is an object
        if (jsonMessage && jsonMessage.type === 'next_commands' && typeof jsonMessage.suggest === 'object' && !Array.isArray(jsonMessage.suggest)) {
          console.log("Received suggest data:", e.data);
          setSuggestCommands(Object.entries(jsonMessage.suggest));
          // Set a timeout to clear the suggestions after 10 seconds
          messageTimeoutRef.current = setTimeout(() => {
            setSuggestCommands([]);
          }, 10000);
        } else {
          // Handle invalid 'suggest' format
          console.error("Invalid format for 'suggest':", message);
        }
      } catch (error) {
        // If the message is not JSON, handle it as a normal response
        setHistory(prevHistory => {
          if (prevHistory.length && Date.now() - prevHistory[prevHistory.length - 1].timestamp < 2000) {
            const lastHistory = { ...prevHistory[prevHistory.length - 1] };
            lastHistory.text += message;
            lastHistory.timestamp = Date.now();
            return [...prevHistory.slice(0, -1), lastHistory];
          } else {
            return [...prevHistory, { type: 'response', text: message, timestamp: Date.now() }];
          }
        });
      }
    
      // Do nothing after 2 seconds if no next message
      messageTimeoutRef.current = setTimeout(() => {}, 4000);
    };
  };

  useEffect(() => {
    scrollToBottom();
  }, [history]);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleCloseSnackbar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  const sendCommand = () => {
    if (!input.trim()) return;
    
    // 入力が"?"で始まる場合の処理
    if (input.startsWith('?')) {
      const query = input.slice(1).trim();
      const lastCommand = history.filter(item => item.type === 'command').pop();
  
      // 直近のコマンドの出力を取得
      let result = history[history.length - 1]?.text;
  
      // コマンドの出力が1000文字を超える場合の処理
      if (result.length > 1000) {
        result = result.substring(0, 500) + '...[省略]...' + result.substring(result.length - 500);
      }
  
      const requestData = {
        command: lastCommand?.text,
        result: result, // 編集された出力を使用
        query: query
      };
  
      ws.current.send('? ' + JSON.stringify(requestData)); // サーバーへJSON形式で送信
    } else {
      ws.current.send(input);
    }
  
    // コマンド送信履歴に追加
    setHistory(prevHistory => [...prevHistory, { type: 'command', text: `$ ${input}` }]);
    setInput('');
  };
  
  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (suggestCommands.length) {
      setSuggestCommands([]);
    }
  };

  const selectSuggestCommand = (command) => {
    setInput(command);
    setSuggestCommands([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendCommand();
    }
  };

  useEffect(() => {
    if (suggestCommands.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }, [suggestCommands]);
  
  const handleSelectSuggestCommand = (command) => {
    setInput(command);
    setSuggestCommands([]);
    setShowDropdown(false);
    setTimeout(() => sendCommand(), 0);
  };
  
  const handleQuestionMarkClick = () => {
    setInput('?');
    setTimeout(() => sendCommand(), 0);
  };

  return (
    <>
      <Paper style={{ padding: '2px 4px', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
          {history.map((item, index) => (
            <pre key={index} style={{ color: item.type === 'command' ? 'blue' : 'black', whiteSpace: 'pre-wrap' }}>{item.text}</pre>
          ))}
          <div ref={bottomRef} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputBase
            id="input-command"
            style={{ marginLeft: '8px', flex: 1 }}
            placeholder="Enter command"
            inputProps={{ 'aria-label': 'enter command' }}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={!isConnected} // Disable input when not connected
          />
          <IconButton onClick={handleQuestionMarkClick} style={{ padding: '10px' }} aria-label="question" disabled={!isConnected}>
            <HelpOutlineIcon />
          </IconButton>
          <IconButton onClick={sendCommand} style={{ padding: '10px' }} aria-label="send" disabled={!isConnected}>
            <SendIcon />
          </IconButton>

          {showDropdown && (
          <div style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', border: '1px solid #ddd', marginTop: '2px' }}>
          {suggestCommands.map(([command, description], index) => (
          <div key={index} style={{ padding: '10px', cursor: 'pointer' }} onClick={() => handleSelectSuggestCommand(command)}>
            <strong>{command}</strong> - {description}
          </div>
          ))}
      </div>
    )}

        </div>
      </Paper>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => handleCloseSnackbar('close')}>
        <Alert onClose={() => handleCloseSnackbar('close')} severity="error" sx={{ width: '100%' }}>
          Connection lost! Attempting to reconnect...
        </Alert>
      </Snackbar>
      <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={() => handleCloseSnackbar('close')}>
        <Alert onClose={() => handleCloseSnackbar('close')} severity="success" sx={{ width: '100%' }}>
          Connection established!
        </Alert>
      </Snackbar>
    </>
  );
}


export default Console;