import React from 'react';
import { Container, Grid, Paper } from '@mui/material';

import Console from './Console';

function App() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        {/* コンソール表示エリア */}
        <Grid item xs={16} md={14}>
        <Paper style={{ height: '90vh', overflow: 'auto' }}>
          <Console /> {/* ここにコンソールコンポーネントを挿入 */}
        </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
